@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: rgb(229, 231, 235);
  background: linear-gradient(180deg, rgba(229, 231, 235, 1) 0%, rgb(196, 198, 201) 100%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  text-transform: uppercase;
  font-size: .75rem;
  line-height: 1rem;
  font-weight: bold;
  width: 100%;
}

input,
select,
textarea {
  font-size: .9rem;
  border-bottom: 1px solid silver;
}